<template>
  <div class="router-error-404-catchall">
    <titleBar
      :title="'Posted!'"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <empty
      title="Well done!"
      sub="You're post have been published"
      desc="hehehe"
      :btTo="postUrl"
      btLabel="View "
      secondaryLabel="Post another"
      :secondaryTo="newUrl"
    />
    <!--

        
        View post

        Post another...
                    -->
  </div>
</template>
<script>
import e404 from "@/components/e/e404";

export default {
  data() {
    return {
      transitionName: "fade", //default
    };
  },
  components: {
    //   e404,
    //  Footer: Footer
  },
  computed: {
    postUrl() {
      var a = this.$route.params.postId;
      var b = this.$route.params.bot;
      ///her/p/Uv2wArfPf
      return `/${b}/p/${a}`;
    },
    newUrl() {
      var a = this.$route.params.postId;
      var b = this.$route.params.bot;
      ///her/p/Uv2wArfPf
      var re = this.$route.query.re;
      //decode uri
      re = decodeURIComponent(re);

      return re || `/${b}/new`;
    },
  },
};
//frida khalo painting of a uber food delivery guy on a scooter
</script>
